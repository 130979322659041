$theme-colour-1: #18358c;
$theme-colour-2: #4e64a6;
$theme-colour-3: #1bcbf2;
$theme-colour-4: #1c3fa6;
@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";
$bg-img: url("../src/Assets/icon_transparent.png");

.body {
  text-align: center;
}
.wrapper {
  position: fixed;
  height: auto;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
.acm {
  &-menu {
    color: $theme-colour-3;
  }
  &-landing {
    display: block;
    width: 100%;
    height: 86vh;
    background-repeat: no-repeat;
  }
  &-logo {
    height: 50vmin;
    pointer-events: none;
    padding-top: 15vh;
  }

  &-header {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $theme-colour-1;
  }

  &-subheader {
    color: $theme-colour-4;
  }
}

// @keyframes App-logo-spin {
//   from {
//     transform: rotateY(0deg);
//   }
//   to {
//     transform: rotateY(360deg);
//   }
// }
